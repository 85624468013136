import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { updateExperience } from '../services/actions/experienceActions'
import {
	EXPERIENCE_UPDATE_RESET,
	EXPERIENCE_DETAILS_RESET,
} from '../services/constants/experienceConstants'
import { useNavigate, useParams } from 'react-router-dom'

const ExperienceEditAccScreen = () => {
	const { id: experienceId } = useParams()
	const navigate = useNavigate()

	const [successMessage, setSuccessMessage] = useState('')
	const [uploading, setUploading] = useState(false)

	const [accTitle, setAccTitle] = useState('')
	const [accDescription, setAccDescription] = useState('')
	const [accImage, setAccImage] = useState('')

	const dispatch = useDispatch()

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { loading, error, experience } = experienceDetails

	const experienceUpdate = useSelector((state) => state.experienceUpdate)
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = experienceUpdate

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (successUpdate) {
			dispatch({ type: EXPERIENCE_UPDATE_RESET })
			dispatch({ type: EXPERIENCE_DETAILS_RESET })
			setSuccessMessage('Experience Successfully Updated')
			setTimeout(() => {
				setSuccessMessage('')
			}, 5000)
		} else {
			if (!experience.name || experience._id !== experienceId) {
			} else {
				setAccTitle(experience.accTitle || '')
				setAccDescription(experience.accDescription || '')
				setAccImage(experience.accImage || '')
			}
		}
	}, [experience, experienceId, dispatch, navigate, successUpdate, userInfo])

	const uploadFileHandler = async (e) => {
		const file = e.target.files[0]
		const formData = new FormData()
		formData.append('image', file)
		setUploading(true)

		try {
			const config = {
				headers: {
					'Content-type': 'multipart/form-data',
				},
			}
			const { data } = await axios.post('/api/upload', formData, config)

			setAccImage(data)
			setUploading(false)
		} catch (error) {
			console.error(error)
			setUploading(false)
		}
	}

	const submitHandler = (e) => {
		e.preventDefault()
		dispatch(
			updateExperience({
				_id: experienceId,
				accTitle,
				accDescription,
				accImage,
				experienceOrgs: experience.experienceOrgs,
			})
		)
	}

	return (
		<>
			<Row className='align-items-center my-4'>
				<Col>
					<h1>Accommodation</h1>
				</Col>
			</Row>
			<FormContainer>
				{loadingUpdate && <Loader />}
				{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
				{loading ? (
					<Loader />
				) : error ? (
					<Message variant='danger'>{error}</Message>
				) : (
					<Form onSubmit={submitHandler}>
						{successMessage && (
							<Message variant='success'>{successMessage}</Message>
						)}
						<Form.Group controlId='accTitle'>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter name of accommodation'
								value={accTitle}
								onChange={(e) => setAccTitle(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='accDescription'>
							<Form.Label>Description</Form.Label>
							<Form.Control
								as='textarea'
								placeholder='Enter description'
								value={accDescription}
								onChange={(e) => setAccDescription(e.target.value)}
								rows={5}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='accImage'>
							<Form.Label>Image</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter image URL'
								value={accImage}
								onChange={(e) => setAccImage(e.target.value)}
							></Form.Control>
							<Form.File
								id='image-file'
								label='Choose File'
								custom
								onChange={uploadFileHandler}
							></Form.File>
							{uploading && <Loader />}
						</Form.Group>

						<Button type='submit' variant='primary'>
							Update
						</Button>
					</Form>
				)}
			</FormContainer>
		</>
	)
}

export default ExperienceEditAccScreen
