import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import DetailHeader from '../components/DetailHeader'
import OrgAdminNav from '../components/OrgAdminNav/OrgAdminNav'
import { Container } from 'react-bootstrap'
import { getExperienceDetails } from '../services/actions/experienceActions'
import { getOrgDetails } from '../services/actions/orgActions'
import { getUserDetails } from '../services/actions/userActions'

const ExperienceManageLayout = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { id: experienceId, slug: orgSlug } = useParams()

	const isManagePath = window.location.pathname.includes('manage')

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { experience } = experienceDetails

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo: userCredInfo } = userLogin
	const { user } = useSelector((state) => state.userDetails)

	const orgDetails = useSelector((state) => state.orgDetails)
	const { org } = orgDetails

	useEffect(() => {
		if (!experience.name || experience._id !== experienceId) {
			dispatch(getExperienceDetails(experienceId))
		}
	}, [experience, experienceId, dispatch])

	useEffect(() => {
		if (!org?._id || org?.slug !== orgSlug) {
			if (orgSlug) {
				dispatch(getOrgDetails(orgSlug))
			}
		}
	}, [dispatch, orgSlug, org?._id, org?.slug])

	useEffect(() => {
		// Redirect to login if user credentials are missing
		if (!userCredInfo) {
			navigate(`/login`)
		} else if (!user?._id) {
			// Fetch user details if not already present
			dispatch(getUserDetails('profile'))
		}
	}, [dispatch, navigate, experience, userCredInfo, user, org, orgSlug])

	return (
		<div>
			{isManagePath && <OrgAdminNav />}
			<DetailHeader />
			<Container>
				<Outlet />
			</Container>
		</div>
	)
}

export default ExperienceManageLayout
