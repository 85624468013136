import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Table, Button, Row, Col, Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {
	getExperienceDetails,
	updateExperience,
} from '../../services/actions/experienceActions'
import {
	EXPERIENCE_UPDATE_RESET,
	EXPERIENCE_DETAILS_RESET,
} from '../../services/constants/experienceConstants'

const ExperienceListTripOptionScreen = () => {
	const { id: experienceId } = useParams()
	const dispatch = useDispatch()
	const [successMessage, setSuccessMessage] = useState('')

	// Modal state
	const [isCreatingTripOption, setIsCreatingTripOption] = useState(false)
	const [uploading, setUploading] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [editingTripOption, setEditingTripOption] = useState(null)
	const [formData, setFormData] = useState({
		title: '',
		description: '',
		image: '',
		fullPrice: 0,
		status: 'Available',
		showSizing: false,
	})

	const experienceDetails = useSelector((state) => state.experienceDetails)
	const { loading, error, experience } = experienceDetails

	const experienceUpdate = useSelector((state) => state.experienceUpdate)
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = experienceUpdate

	useEffect(() => {
		if (successUpdate) {
			dispatch({ type: EXPERIENCE_UPDATE_RESET })
			dispatch({ type: EXPERIENCE_DETAILS_RESET })
			setSuccessMessage('Experience Successfully Updated')
			setTimeout(() => {
				setSuccessMessage('')
			}, 3000)
		}
		if (!experience?.name || experience._id !== experienceId) {
			dispatch(getExperienceDetails(experienceId))
		}
	}, [dispatch, experience, experienceId])

	const openModal = (tripOption = null) => {
		setIsCreatingTripOption(!tripOption) // If no tripOption, we are creating
		setEditingTripOption(tripOption)
		setFormData({
			title: tripOption?.title || '',
			description: tripOption?.description || '',
			image: tripOption?.image || '',
			fullPrice: tripOption?.fullPrice || 0,
			status: tripOption?.status || 'Available',
			showSizing: tripOption?.showSizing || false,
		})
		setShowModal(true)
	}

	const closeModal = () => {
		setShowModal(false)
		setEditingTripOption(null)
		setIsCreatingTripOption(false)
	}

	const handleFormChange = (e) => {
		const { name, value, type, checked } = e.target
		setFormData((prev) => ({
			...prev,
			[name]: type === 'checkbox' ? checked : value,
		}))
	}

	const saveHandler = (e) => {
		e.preventDefault()

		const updatedTripOptions = isCreatingTripOption
			? [...experience.tripOption, formData]
			: experience.tripOption.map((item) =>
					item._id === editingTripOption._id ? { ...item, ...formData } : item
			  )

		dispatch(
			updateExperience({
				_id: experienceId,
				tripOption: updatedTripOptions,
				experienceOrgs: experience.experienceOrgs,
			})
		).then(() => dispatch(getExperienceDetails(experienceId)))
		closeModal()
	}

	const uploadFileHandler = async (e) => {
		const file = e.target.files[0]
		const formDataObj = new FormData()
		formDataObj.append('image', file)
		setUploading(true)

		try {
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
			const { data: imageData } = await axios.post(
				'/api/upload',
				formDataObj,
				config
			)

			setFormData((prev) => ({
				...prev,
				image: imageData,
			}))
			setUploading(false)
		} catch (error) {
			console.error(error)
			setUploading(false)
		}
	}

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure you would like to do this?')) {
			const updatedTripOption = experience.tripOption.filter(
				(item) => item._id !== id
			)

			dispatch(
				updateExperience({
					_id: experienceId,
					tripOption: updatedTripOption,
					experienceOrgs: experience.experienceOrgs,
				})
			).then(() => dispatch(getExperienceDetails(experienceId)))
		}
	}

	return (
		<>
			<Row className='align-items-center my-4'>
				<Col>
					<h1>Trip Options</h1>
				</Col>
				<Col className='text-right'>
					<Button onClick={() => openModal()}>
						<i className='fas fa-plus'></i> Create Trip Option
					</Button>
				</Col>
			</Row>
			{errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
			{loading || loadingUpdate ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : experience.tripOption?.length > 0 ? (
				<>
					{successMessage && (
						<Message variant='success'>{successMessage}</Message>
					)}
					<Table striped bordered hover responsive className='table-sm'>
						<thead>
							<tr>
								<th>TITLE</th>
								<th>DESCRIPTION</th>
								<th>PRICE</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{experience.tripOption.map((item) => (
								<tr key={item._id}>
									<td>{item.title}</td>
									<td>{item.description}</td>
									<td>${item.fullPrice.toFixed(2)}</td>
									<td>
										<Button
											variant='light'
											className='btn-sm'
											onClick={() => openModal(item)}
										>
											<i className='fas fa-edit'></i>
										</Button>
										<Button
											variant='danger'
											className='btn-sm'
											onClick={() => deleteHandler(item._id)}
										>
											<i className='fas fa-trash'></i>
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</>
			) : (
				<h2>No trip options have been created</h2>
			)}
			<Modal show={showModal} onHide={closeModal} centered>
				<Modal.Header closeButton>
					<Modal.Title>
						{isCreatingTripOption ? 'Create Trip Option' : 'Edit Trip Option'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={saveHandler}>
						<Form.Group controlId='tatus'>
							<Form.Label>Status</Form.Label>
							<Form.Control
								as='select'
								name='tripOptionStatus'
								value={formData.status}
								onChange={handleFormChange}
							>
								<option value='Available'>Available</option>
								<option value='Unavailable'>Unavailable</option>
							</Form.Control>
						</Form.Group>

						<Form.Group controlId='title'>
							<Form.Label>Title</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter title'
								name='tripOptionTitle'
								value={formData.title}
								onChange={handleFormChange}
								required
							/>
						</Form.Group>

						<Form.Group controlId='description'>
							<Form.Label>Description</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter description'
								name='tripOptionDescription'
								value={formData.description}
								onChange={handleFormChange}
								autocomplete='off'
								required
							/>
						</Form.Group>

						<Form.Group controlId='fullPrice'>
							<Form.Label>Price</Form.Label>
							<Form.Control
								type='number'
								placeholder='Enter price'
								name='tripOptionPrice'
								value={formData.fullPrice}
								onChange={handleFormChange}
							/>
						</Form.Group>

						<Form.Group controlId='showSizing'>
							<Form.Check
								type='checkbox'
								label='Show Sizing'
								name='tripOptionShowSizing'
								checked={formData.showSizing}
								onChange={handleFormChange}
							/>
						</Form.Group>

						<Form.Group controlId='image'>
							<Form.Label>Image</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter image URL'
								name='tripOptionImage'
								value={formData.image}
								onChange={handleFormChange}
								required
							/>
							<Form.File
								id='image-file'
								label='Choose File'
								custom
								onChange={uploadFileHandler}
							></Form.File>
							{uploading && <Loader />}
						</Form.Group>

						<Button type='submit' variant='primary'>
							{isCreatingTripOption ? 'Create Trip Option' : 'Save Changes'}
						</Button>
						<Button variant='secondary' onClick={closeModal} className='ml-2'>
							Cancel
						</Button>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default ExperienceListTripOptionScreen
